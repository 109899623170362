<template>
	<div class="introduce page">
		<div class="mode1">
			<!-- <img v-show="videoPauseShow" src="../../assets/brandStory/introduce/play.png" class="play" @click="$refs.video.play()"/> -->
			<video ref="video" :src="info.introduce.video" controls :poster="info.introduce.image"></video>
		</div>
		<!-- <div class="paddingBox mode3">
			<div>
				<div class="titTop">{{lang==6?'宝爱捷（中国）- 公司概况':'PAIG (China) - Company Profile'}}</div>
				<p class="titText">{{lang==6?'位于杭州的宝爱捷（中国）汽车投资有限公司，也被称为“保时捷汽车投资中国分公司”，是萨尔茨堡保时捷控股有限公司的全资子公司，全面负责中国区业务的运营管理和经销商网络部署。' : 'Known as “Porsche Automotive Investment China Branches”, the Hangzhou-based company PAIG(China) Automobile Investment Co., Ltd is a 100% daughter company of Porsche Holding Salzburg and is fully responsible for all operational business activities and the dealer network development in China.'}}</p>
			</div>
			<div class="table">
				<div>
					<p>100%</p>
					<p>{{lang==6?'隶属于保时捷控股有限公司和大众集团':'Owned by PHS & Volkswagen Group'}}</p>
				</div>
				<div>
					<p>2005</p>
					<p>{{lang==6?'进入中国市场':'Entered the Chinese market '}}</p>
				</div>
				<div>
					<p>43</p>
					<p>{{lang==6?'汽车经销商':'Dealerships, covering Audi, Bentley'}}<br>
						{{lang==6?'品牌覆盖保时捷、宾利、奥迪、宝马/MINI':'Lamborghini, Porsche, BMW/MINI'}}</p>
				</div>
			</div>
		</div> -->
		<div style="padding-bottom: 20px;">
			<div class="paddingBox" style="margin-bottom: 30px;">
				<div class="titTop">{{lang==6?'宝爱捷（中国）':'PAIG (China)'}}</div>
			</div>
			<div class="paddingBox mode1-tit">
				<div v-if="lang==6">
					宝爱捷（中国）汽车投资有限公司是德国大众集团旗下萨尔茨堡保时捷控股有限公司的全资子公司。<br><br>

					欧洲最大汽车零售公司——萨尔茨堡保时捷控股从2005年进入中国市场，成立第一家豪华品牌经销商。随着中国市场业务的快速发展壮大，2012年在杭州成立了宝爱捷（中国）汽车投资有限公司，全面负责中国区经销商网络的部署和业务的运营管理。<br><br>

					时至今日，我们已建立45家汽车经销商，主要集中在奥迪、宾利、兰博基尼、保时捷和 BMW/MINI 品牌。<br><br>

					作为一家来自欧洲的豪华汽车品牌经销商投资人，我们在豪华车零售领域有着丰富的实践经验，并将进一步投资拓展豪华高端市场的品牌网络布局。我们将持续专注于客户服务和客户体验，为当地客户带来高品质的产品和至臻的体验。
				</div>
				<div v-else>
					PAIG (China) Automobile Investment Co., Ltd (also known as "Porsche Automotive Investment, China
					Branches") is a 100% daughter company of Porsche Holding Salzburg, which is also fully owned by
					Volkswagen Group.<br><br>

					Porsche Holding Salzburg, the largest automotive trading company in Europe, entered China market
					with the first premium brand dealership opening in 2005. With its fast and sustainable development
					and expansion in China, PAIG (China) Automobile Investment (hereinafter referred to as PAIG (China))
					was established in 2012, and is fully responsible for all operational business activities and the
					dealer network development in China.<br><br>

					We have established 45 automotive dealers in China up to now, covering the premium brands of Audi,
					Bentley, Lamborghini, Porsche and BMW/MINI. <br><br>

					As a major premium brand dealer group from Europe, PAIG possess profound knowledge and rich
					practical experience in the premium automotive retail business. Further growth in the premium and
					luxury automotive segments are the main strategic focus of PAIG in China. Meanwhile, customer
					service and customer experience remain as the continuous focus. We are committed to providing high
					quality products and exclusive customer experience to the local market.

				</div>
			</div>
			<!-- <img style="width: 100%;" :src="`${require('../../assets/brandStory/introduce/img2'+(lang==6?'':'-EN')+'.png')}`" /> -->
		</div>
		<div class="mode2" :style="`background-image: url(${require('../../assets/brandStory/introduce/bg.png')});`">
			<div class="mode2-text">
				<div class="titTop" style="line-height: .8;">
					<!-- Porsche Holding Salzburg -->
					&nbsp;
				</div>
				<div class="titBottom">{{lang==6?'萨尔茨堡保时捷控股':'Porsche Holding Salzburg'}}</div>
				<p class="titText mode1-tit" v-if="lang==6">

					萨尔茨堡保时捷控股有限公司是欧洲最大的汽车零售公司。<br>
					位于萨尔茨堡的公司由费迪南德·保时捷的两个孩子路易丝·皮耶希和费利·保时捷于1947年创立。<br>
					作为大众集团的100％的全资子公司，如今，萨尔茨堡保时捷控股公司开展大众集团品牌的批售、零售和售后服务业务。<br><br>
					<a href="https://www.porsche-holding.com/en">www.porsche-holding.com/en</a>
				</p>
				<p class="titText mode1-tit" v-else>

					Porsche Holding Salzburg is Europe's largest automotive retail company.<br>
					The Salzburg-based company was founded in 1947 by Ferdinand Porsche's two children, Louise Piëch and
					Ferry Porsche.<br>
					As a 100% wholly owned subsidiary of the Volkswagen Group, today, Porsche Holding Salzburg holds the
					wholesale, retail and after-sales services of the Volkswagen Group brands.<br><br>
					<a href="https://www.porsche-holding.com/en">www.porsche-holding.com/en</a>
				</p>
			</div>
			<!-- <div class="imgBox">
				<img src="../../assets/brandStory/introduce/img4.png" />
			</div> -->
		</div>
		<!-- <div class="paddingBox mode4">
			<div class="titTop">{{lang==6?'萨尔茨堡保时捷控股 - 历史发展':'PHS-History'}}</div>
			<div class="mode4-content"  :class="lang!=6?'mode4-contentEN':''">
				<img src="../../assets/brandStory/introduce/line.png"/>
				<div class="line">
					<div>1943</div>
					<div>{{lang==6?'费迪南德保时捷和费利·保时捷共同开发了大众甲壳虫汽车':'Development of the VWBeetle by Ferdinand Porsche and Ferry Porsche'}}</div>
				</div>
				<div class="line">
					<div>1947</div>
					<div>{{lang==6?'1947年：路易丝皮耶希和费利·保时捷在格明德成立保时捷公司。':'1947:Foundation of the Porsche company in Gmund by Louise Piech and Ferry Porsche. '}}</div>
				</div>
				<div class="line">
					<div>1949</div>
					<div>{{lang==6?'1949年：他们通过在奥地利进口和销售甲壳虫汽车，为成功创建萨尔茨堡保时捷控股公司奠定了基础。':'In 1949 they laid_the foundations for the successful creation of Porsche Holding Salzburg with the import and sale of the Volkswagen Beetle in Austria. '}}</div>
				</div>
				<div class="line">
					<div>2011</div>
					<div>{{lang==6?'2011年3月，萨尔茨堡保时捷控股有限公司成为大众集团的全资子公司，为大众集团全球销售业务注入了丰富专业的汽车领域经验。':"Since March 2011, Porsche Holding Salzburg has been a100% subsidiary of Volkswagen AG and has contributed its decades of know-how in the automotive business to theVolkswagen Group's global sales. "}}</div>
				</div>
				<div class="line">
					<div>2021</div>
					<div>{{lang==6?'截至2021年底，萨尔茨堡保时捷控股有限公司共有34,000余名员工，全年总计售出超过887,900辆新车及二手车。':'At the end of 2021, around 34,000 people were employed at Porsche Holding Salzburg and around 887,900 new cars and used cars were sold.'}}</div>
				</div>
			</div>
		</div> -->
		<!-- <div class="paddingBox">
			<div class="titTop">{{lang==6?'萨尔茨堡保时捷控股  -  经销商网络':'PHS - The International Retail Locations'}}</div>
			<img :src="`${require('../../assets/brandStory/introduce/img3'+(lang==6?'':'-EN')+'.png')}`" />
		</div> -->

		<!-- <div class="mode2" :style="`background-image:url(${info.introduce.background})`">
			<div class="mode2-text">
				<div class="titTop">Porsche Holding Salzburg</div>
				<div class="titBottom">{{info.holding.title}}</div>
				<p class="titText">{{info.holding.describe}}
				</p>
			</div>
			<div class="imgBox">
				<img :src="info.holding.atlas" />
			</div>
		</div> -->
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	import mixinLang from '../../components/mixin.js'
	export default {
		name: 'introduce',
		mixins: [mixinLang],
		components: {
			swiper,
			swiperSlide
		},
		data() {
			return {
				// 轮播配置
				swiperOption: {
					slidesPerView: "auto",
					loop: false,
				},
				info: {
					introduce: {},
					holding: {}
				},
				videoPauseShow: true,
				atlasList: [] //图集
			}
		},
		mounted() {
			// var geolocation = new BMapGL.Geolocation();
			// var location
			// var city
			// var url
			// // 获取位置
			// geolocation.getCurrentPosition(function(r) {
			// 	location = r.latitude + ',' + r.longitude
			// 	city = r.address.city
			// 	url = 'http://api.map.baidu.com/direction?origin=latlng:' + location +
			// 		'|name:我的位置&destination=浙江省杭州市滨江区西兴路2333号星澜大厦4幢16层&mode=driving&origin_region=' + city +
			// 		'&destination_region=杭州&output=html&src=webapp.baidu.openAPIdemo'
			// })
			// var map = new BMapGL.Map('map');
			// map.enableScrollWheelZoom(true);
			// var point = new BMapGL.Point(120.227974, 30.221548);
			// map.centerAndZoom(point, 17);
			// // 创建点标记
			// var marker = new BMapGL.Marker(point);
			// map.addOverlay(marker);
			// // 创建信息窗口
			// var opts = {
			// 	width: 200,
			// 	height: 100,
			// };
			// var infoWindow = new BMapGL.InfoWindow('<div id="infoWindow">地址：浙江省杭州市滨江区西兴路2333号星澜大厦4幢16层</div>', opts);
			// map.openInfoWindow(infoWindow, point); // 开启信息窗口
			// if (!infoWindow.isOpen()) {
			// 	infoWindow.addEventListener("open", function() {
			// 		document.getElementById("infoWindow").onclick = function(e) {
			// 			window.open(url)
			// 		}
			// 	})
			// } else {
			// 	document.getElementById("infoWindow").onclick = function(e) {
			// 		window.open(url)
			// 	}
			// }
			// // 点标记添加点击事件
			// marker.addEventListener('click', function() {
			// 	map.openInfoWindow(infoWindow, point); // 开启信息窗口
			// });
		},
		methods: {
			getInfo() {
				this.$api.getIntroduction().then(res => {
					// res.holding.atlas = res.holding.atlas.split('|')
					this.info = res
				})
				// this.$api.getBannerList({
				// 	cate_no: '3c545547'
				// }, {
				// 	page: 1,
				// 	limit: 100
				// }).then(res => {
				// 	var num = Math.round(res.banner_list.length / 2)
				// 	this.atlasList = [res.banner_list.slice(0, num), res.banner_list.slice(num)]
				// })
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		padding-top: 0;
	}

	.introduce {
		background: #101621;
		padding-bottom: 0px;
		color: #FFF;
	}

	.titText {
		// width: 860px;
	}

	.paddingBox {
		padding: 0 160px;
		margin-bottom: 100px;

		img {
			width: 100%;
			margin-top: 50px;
		}

		.titTop {
			color: #FFF;
			font-size: 52px;
		}

		.titText {
			width: 100%;
			margin-bottom: 40px;
		}
	}

	.mode1 {
		padding-bottom: 80px;
		color: #FFF;
		position: relative;

		.play {
			width: 180px;
			height: 170px;
			position: absolute;
			left: calc(50% - 90px);
			top: calc(50% - 85px);
			z-index: 99;
		}

		.mode1-tit {
			font-family: SourceHanSansCN-Medium, sans-serif;
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			line-height: 60px;
			margin-bottom: 80px;
			padding: 0 100px;

			.mode1-tit-big {
				font-family: Montserrat-Bold, sans-serif;
				font-size: 48px;
			}
		}

		video {
			width: 100%;
			height: 1080px;
			background-color: #101621;
			object-fit: fill;
		}

		.mode1-text {
			width: 1600px;
			margin: 0 auto;
			font-family: SourceHanSansCN-Light, sans-serif;
			font-size: 18px;
			font-weight: 300;
			line-height: 50px;
			margin-top: 80px;
		}
	}

	.mode2 {
		width: 100%;
		height: 980px;
		background-color: #FFF;
		position: relative;
		background-size: 100% 100%;
		// font-size: 20px;
		// font-family: SourceHanSansCN-Light,Microsoft YaHei New, Microsoft Yahei, 微软雅黑, Hiragino Sans GB, 冬青黑体, Arial, Helvetica, SimHei, 黑体, STXihei, 华文细黑, sans-serif;
		margin-bottom: 0px;

		.mode2-text {
			position: absolute;
			top: 80px;
			left: 160px;

			.titBottom {
				font-size: 52px;
				color: #FFF;
			}

			.titText {
				// color: #000;
				width: 970px;
				margin-top: 60px;

				a {
					color: #FFF;
					text-decoration: none;
				}
			}
		}

		.imgBox {
			position: absolute;
			display: flex;
			// width: 1048px;
			// height: 340px;
			background: #FFFFFF;
			border: 2px solid #97233F;
			justify-content: space-between;
			left: 160px;
			bottom: 85px;

			&::after {
				content: '';
				width: 274px;
				height: 191px;
				background: #97233F;
				display: block;
				position: absolute;
				right: -10px;
				bottom: -10px;
				z-index: 1;

			}

			img {
				width: 521px;
				height: 100%;
				vertical-align: middle;
				z-index: 2;
				background-color: #FFF;

				&:nth-child(1) {
					// border-right: 2px solid #97233F;
				}
			}
		}
	}

	.table {
		border: 1px solid #004C6F;
		height: 300px;
		display: flex;
		text-align: center;
		justify-content: space-between;
		margin-bottom: 40px;
		border-radius: 16px;
		background: linear-gradient(180deg, #163046 0%, #101621 100%);
		// border-image: linear-gradient(281deg, #0065C4 -1%, rgba(0,196,255,0.27) 27%, #009BCA 53%, rgba(0,196,255,0.31) 77%, #0086AF 100%) 1;

		/* 地图色块 */
		box-shadow: 0px 0px 10px 0px #004C6F;

		>div {
			flex: 1;
			font-family: SourceHanSansCN-Light, sans-serif;
			font-size: 22px;
			font-weight: 300;
			line-height: 30px;
			padding-top: 30px;
			// border-right: 1px solid #97233F;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				height: 100%;
				width: 1px;
				background: -webkit-linear-gradient(top, #101621 -4%, #61DAFF 50%, #101621 100%);
				top: 0;
				right: 0;
			}

			&:last-child {
				&::after {
					display: none;
				}
			}

			p:nth-child(1) {
				// color: #97233F;
				font-family: SourceHanSansCN-Bold, sans-serif;
				font-size: 66px;
				font-weight: bold;
				line-height: 164px;

			}
		}
	}

	.mode4 {
		background-color: #FFF;
		// color: #000;
		margin-bottom: 0;
		padding-top: 70px;
		padding-bottom: 60px;

		.titTop {
			// color: #000;
		}

		.mode4-content {
			position: relative;
			padding-top: 40px;
			font-family: SourceHanSansCN-Light, sans-serif;
			font-size: 22px;
			font-weight: 300;

			img {
				position: absolute;
				width: 100px;
				height: 95%;
				// height: calc(100% - 50px);
				top: 0px;
				left: 100px;
				margin: 0;
			}

			>div {
				display: flex;
				align-items: flex-start;
				// margin-bottom: 40px;
				height: 100px;

				div:nth-child(1) {
					color: #97233F;
					font-family: SourceHanSansCN-Bold, sans-serif;
					font-size: 30px;
					font-weight: bold;
					margin-right: 150px;
				}

				div:nth-child(2) {
					line-height: 40px;
				}
			}
		}
	}
</style>